import React from "react";
import "./Header.scss";
import logo from "../../assets/images/logo.png";
import SmallScreenHeader from "../SmallScreenHeader";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function Header() {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const token =
    Cookies.get("authToken") && JSON.parse(Cookies.get("authToken"));

  return (
    <>
      {open ? (
        <SmallScreenHeader
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : (
        <ion-icon
          name="menu-outline"
          size="large"
          onClick={() => setOpen(true)}
        ></ion-icon>
      )}
      <div className="header">
        {/* <img className="header__logo" src={logo}></img> */}

        <div className="nav_container">
          <img className="header__logo" src={logo} alt=""></img>
          <nav className="header__menu">
            {/* <div> */}
            <span
              className={`link ${location.pathname === "/" && "selected"}`}
              onClick={() => history.push("/")}
            >
              Home
            </span>
            {/* <span
              className={`link ${
                location.pathname === "/videos" && "selected"
              }`}
              onClick={() => history.push("/videos")}
            >
              Videos
            </span> */}
            <span
              className={`link ${location.pathname === "/about" && "selected"}`}
              onClick={() => history.push("/about")}
            >
              About us
            </span>
            {/* </div> */}

            <span
              className={`link ${
                location.pathname === "/productions" && "selected"
              }`}
              onClick={() => history.push("/productions")}
            >
              Productions
            </span>
            <span
              className={`link ${
                location.pathname === "/events" && "selected"
              }`}
              onClick={() => history.push("/events")}
            >
              Events
            </span>
            <span
              className={`link ${
                location.pathname === "/contact" && "selected"
              }`}
              onClick={() => history.push("/contact")}
            >
              Contact
            </span>
            {token ? (
              <span
                className={`link `}
                onClick={() => {
                  Cookies.remove("authToken");
                  history.push("/");
                }}
              >
                Sign Out
              </span>
            ) : (
              <span
                className={`link ${
                  location.pathname === "/signin" && "selected"
                }`}
                onClick={() => history.push("/signin")}
              >
                Sign In
              </span>
            )}
          </nav>

          {/* <img src={logo}></img> */}
        </div>
      </div>
    </>
  );
}

export default Header;
