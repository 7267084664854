import React from "react";
import "./Contact.scss";
import Header from "../../Components/Header";

function Contact() {
  return (
    <>
      <Header />
      <div className="contact">
        <h4>Contact us</h4>
        <div className="contact__data">
          <ion-icon name="location-outline" size="large"></ion-icon>
          <div className="contact__data__container">
            <span>Address: </span>
            <p>
              Nashville Country Television Network,
              &nbsp;2416 Music Valley Drive,
              &nbsp;Nashville, TN 37214
            </p>
          </div>
        </div>

        <div className="contact__data">
          <ion-icon name="call-outline" size="large" />
          <div className="contact__data__container">
            <span>Phone: </span>
            <p>+1-(615)-612-0163</p>
          </div>
        </div>

        <div className="contact__data">
          <ion-icon name="mail-outline" size="large"></ion-icon>
          <div className="contact__data__container">
            <span>Email: </span>
            <p><a href="mailto:info@nctvn.com">info@nctvn.com</a></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
