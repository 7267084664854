import React from "react";
import "./Production.scss";
import Header from "../../Components/Header";
import img12 from "../../assets/images/img12.png";
import img7 from "../../assets/images/img7.webp";
import img8 from "../../assets/images/img8.webp";
import img9 from "../../assets/images/img9.png";
import img10 from "../../assets/images/img10.jpg";

function Productions() {
  return (
    <>
      <Header />
      <div className="productions">
        <div>
          <h4>Productions</h4>

          <div className="productions__container">
            {/* <div className="productions__container__item">
              <img src={img6} alt="" />
              <div>
                <span>"Women On Fire" with Erin McLendon</span>
                <p>
                  Americana like you have not seen before! "Women On Fire",
                  produced by Joe Tine Productions, is hosted by Erin McLendon.
                  The show has an all-female band and guests. Erin, an energetic
                  performer and songwriter, is a member of The Tennessee
                  Songwriters Association International. She has hosted this
                  show at the EXIT/IN on West End Avenue in Nashville, TN. from
                  time to time. Joe and Erin are shooting promos and some
                  performances while dealing with the coronavirus issues. It is
                  a challenging endeavour, but with creativity and perseverance,
                  they will produce an awesome show for the Nashville Country
                  Television Network.{" "}
                </p>
              </div>
            </div> */}
            <div className="productions__container__item">
              <img src={img12} alt="" />
              <div>
                <span>Legends of Country Music</span>
                <p>
                  Legends of Country Music, is another Nashville Country
                  Television Network show hosted and produced by Tony Pratt. If
                  you are nostalgic and long for traditional Country Music from
                  back in the day, this show will share legendary music
                  including previously unreleased and/or unpublished recordings
                  by deceased Nashville music artists and songwriters as well as
                  updated versions by the artists of today. Tony’s Dad was
                  Rockabilly hall of fame singer Vern Pratt. Vern Pratt toured
                  with Merle Haggard, Conway Twitty, Loretta Lynn and Bluegrass
                  legend Bill Monroe. He performed in shows with rock legends
                  Jerry Lee Lewis and Dobie Gray. He was close friends with
                  Faron Young, Mel Tillis, Jerry Reed, Charlie Daniels and
                  Willie Nelson. Guest artist for the first episode of Legends
                  will be Cash Creek Band featuring Kimo Forrest, former lead
                  guitarist for Alabama. The title of the retrofitted song Cash
                  Creek Band will be recording for the show is “Memphis Was On A
                  Roll.” Born out of the great depression, this amazing song
                  documents how legendary producer Sam Phillips at Sun Records
                  launched a new era of American music with the meteoric rise of
                  the Million Dollar Quartet which included Elvis Presley, Jerry
                  Lee Lewis, Carl Perkins and Johnny Cash. The previously
                  unreleased song was published by deceased BMI songwriters
                  Charles W. Lewis, Jim Johnston, and Ronnie Flannigan. The
                  original music was produced by my dad Vern Pratt.
                </p>
              </div>
            </div>

            <div className="productions__container__item reverse">
              <img src={img7} alt="" />
              <div>
                <span>Russ Lacasse</span>
                <p>
                  "A Songwriters LIfe" is a show hosted by Russ Lacasse. It is a
                  reality show based on the activities of a Nashville
                  Songwriter. Russ is the host of a Friday evening songwriters
                  showcase at Belcourt Tapps in Nashville. The show features
                  various artists and songwriters, including hit songwriters.
                  His Television show production will provide a look inside the
                  writer's life of writing, co-writing, performing, promoting,
                  networking and lifestyle of Nashville songwriters. Russ is a
                  board member of the Tennessee Songwriters Association
                  International. In the few years Russ has been in Nashville, he
                  has had several cuts, including two on the "Dancing With The
                  Dinosaurs CD" by "Cash Creek Band".
                </p>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            {/* <div> */}
            {/* <h4>Productions</h4> */}

            {/* <div className="productions__container"> */}
            <div className="productions__container__item">
              <img src={img8} alt="" />
              <div>
                <span>The Music City Miracle Hour</span>
                <p>
                  Producer Tom Harding ,pictured, "The Music City Miracle Hour"
                  will feature rotating VJ’s introducing videos of both new
                  independent artists and established legacy artists. Every
                  hour-long episode will have at least one interview with an
                  artist whose video is being featured. Tom is a producer in
                  Franklin TN who has produced many great artists and has had
                  several number 1 hits on the International Country Charts. His
                  artists highly recommend him as one of the great producers.
                  Tom will be producing another show or two for our network.
                </p>
              </div>
            </div>

            <div className="productions__container__item reverse">
              <img src={img9} alt="" />
              <div>
                <span>The Side Music</span>
                <p>
                  "The Side Music" is a show about the musicians who back up the
                  stars. They are called "Side Musicians". Derwin Hinson
                  himself, and his wife Alene, pictured, have lived this life
                  with major stars. Because of this, he knows who these
                  musicians are. They are some of the greatest of all time,
                  having travelled or still travelling with major stars. These
                  musicians will share their road stories on this show. These
                  are awesome untold stories! Derwin and his wife Alene also
                  have a duo act where they sing and play gospel and traditional
                  country together. Derwin plays many instruments including
                  guitar, banjo, mandolin and bass. However, his wife Alene
                  plays an upright bass when they play together. In fact, they
                  are now booking country and gospel shows for house parties,
                  nursing homes and small private events. If you are interested,
                  you can email them at{" "}
                  <a
                    href="mailto:hinsonderwin@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hinsonderwin@gmail.com
                  </a>
                </p>
              </div>
            </div>

            <div className="productions__container__item">
              <img src={img10} style={{ height: 220 }} alt="" />
              <div>
                <span>Weird News</span>
                <p>
                  Weird News Network, hosted and produced by renowned paranormal
                  researcher Tony Pratt is a new show on the Nashville Country
                  Television Network devoted to exploring unsolved mysteries of
                  science and nature. In 1998 Tony’s own UFO video footage was
                  rated by Dr. Steven Greer of the Disclosure Project to be in
                  the top three photographic evidence for an ET presence on
                  Earth. Dr. Greer has briefed every President since Clinton on
                  the UFO issue. WNN will examine and report on photographic
                  evidence from around the world and conduct regular contests
                  offering cash and prizes featuring viewer submitted video and
                  photo(s) in categories that include UAP/UFO, Bigfoot/Cryptids,
                  Ghosts/Spirit, Anomalies of Nature and Hoaxes/Debunking. The
                  WNN Weird News Network evolved from Tony Pratt’s personal
                  experiences and research over the past Twenty three years in
                  the field of Ufology and paranormal investigations. Tony is
                  proud to announce his co-host for WNN will be premiere
                  paranormal researcher and the host of Para-reality Radio, the
                  enigmatic “Sandman.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Productions;
