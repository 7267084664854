import "./magazine.scss";
import Header from "../../Components/Header";

function Magazine() {

  return (
    <div className="signin">
      <Header />
      <div className="signin_wrapper">
        <h1 className="signin_wrapper_header"> Nashville Country Spotlight</h1>
        <div className="wrapper">
          <iframe src="./assets/magazine/App_Usage_FireTV.pdf#toolbar=0" style={{width: '70%', height: '800px'}} ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Magazine;
